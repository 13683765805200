<template>
    <div class="map-container">
        <div id="mapContainer" ref="container"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted() {
        this.initMaps();
    },
    methods: {
        initMaps() {
            const that = this;
            const positionsStr = sessionStorage.getItem("positions");
            const address = sessionStorage.getItem("address");
            const positions = JSON.parse(positionsStr);
            const addressList = JSON.parse(address);
            // 创建地图实例
            var map = new AMap.Map(this.$refs.container, {
                zoom: 13,
                center: positions[0],
                resizeEnable: true,
                // mapStyle: "amap://styles/whitesmoke",
            });
            // var positions = [
            //     [116.493195, 39.993253],
            //     [116.473195, 39.993253],
            //     [116.453195, 39.993253],
            //     [116.493195, 39.973253],
            //     [116.473195, 39.973253],
            //     [116.453195, 39.973253],
            //     [116.493195, 39.953253],
            //     [116.473195, 39.953253],
            //     [116.453195, 39.953253],
            // ];
            var pos_icon = [];
            var pos_marker = [];
            var icon = [];
            var marker = [];
            for (var i = 0; i < positions.length; i++) {
                // 创建一个 Icon
                pos_icon[i] = new AMap.Icon({
                    // 图标尺寸
                    size: new AMap.Size(12, 12),
                    // 图标的取图地址
                    image: "//a.amap.com/jsapi_demos/static/demo-center/marker/marker.png",
                    // 图标所用图片大小
                    imageSize: new AMap.Size(12, 12),
                });
                var labelContent =
                    "<div class='labelContent'>" + addressList[i] + "</div>";

                // 将 Icon 传入 marker
                // pos_marker[i] = new AMap.Marker({
                //     position: positions[i],
                //     icon: pos_icon[i],
                //     anchor: "center", //设置锚点
                //     offset: new AMap.Pixel(0, 0), //设置偏移量
                // });
                // map.add(pos_marker[i]);

                // 创建一个 Icon
                var imageUrl =
                    "//a.amap.com/jsapi_demos/static/demo-center/marker/marker.png";

                icon[i] = new AMap.Icon({
                    // 图标尺寸
                    size: new AMap.Size(26, 26),
                    // 图标的取图地址
                    image: imageUrl,
                    // 图标所用图片大小
                    imageSize: new AMap.Size(26, 26),
                });
                // 将 Icon 传入 marker
                var labelOffset = new AMap.Pixel(0, -5);
                marker[i] = new AMap.Marker({
                    position: positions[i],
                    icon: icon[i],
                    offset: new AMap.Pixel(0, 0), //设置偏移量
                    label: {
                        direction: "top",
                        content: labelContent,
                        offset: labelOffset,
                    },
                });
                console.log(addressList[i], "====addressList[i]====");
                marker[i].on("click", (e) =>
                    that.markerClick(e, addressList, i)
                );
                map.add(marker[i]);
                map.setFitView(null, false, [150, 60, 100, 60]);
            }
        },
        markerClick(e, address, i) {
            console.log(e, address, i, "====address====");
            const { lat, lng } = e.lnglat;
            this.$wxsdk.openLocation({
                latitude: lat,
                longitude: lng,
                name: address,
                address: address,
            });
        },
    },
};
</script>

<style lang="scss">
#mapContainer {
    height: 100vh;
    width: 100%;
}
.labelContent {
    width: 150px;
    height: auto;
    text-align: justify;
    text-justify: newspaper;
    word-break: break-all;
    white-space: normal;
    background: #fff;
    padding: 6px 0;
}
.amap-marker-label {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 24px;
    border: 1px solid #ccc;
}
</style>